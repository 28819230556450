import React from 'react'
import { css } from 'styled-components'
import { useDispatch } from 'react-redux'
import { LightBox } from '../LightBox/LightBox'
import { SHARED_CLEAR_INSTANCE } from '../../constants/sharedConstants'
import { DisclaimerTextDiv } from '../AddToFavSignInModal/AddToFavSignInModal'
export const POBoxShippingWarningModal = () => {
    console.log('POBoxShippingWarningModal render??')
    const dispatch = useDispatch()

    const closeHandler = () => {
        if (document) {
            document.body.style.overflow = 'auto'
        }
        dispatch({ type: SHARED_CLEAR_INSTANCE })
    }

    return (
        <LightBox
            closeHandler={closeHandler}
            show
            xStyles={css`
        position: relative;
        right: 0rem;
      `}
            styles={css`
        padding: 1rem;
      `}
        >
            <DisclaimerTextDiv>
                For orders over 1 pound sent to P.O. boxes, please allow up to 10 business days from ship date for delivery.
            </DisclaimerTextDiv>
        </LightBox>
    )
}